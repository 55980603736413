import '../styles/CustomerDetail.scss';

import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import API from '../util/AdminAPI';
import { useState, useEffect } from 'react';

const CustomerDetail = (props) => {
    const api: API = props.API;

    const customer = props.customer;
    const contact = props.contact;
    const editCustomer = props.editCustomer;
    const newHerd = props.newHerd;
    const newRIL = props.newRIL;
    const changePassword = props.changePassword;
    const editRIL = props.editRIL;
    const editHerd = props.editHerd;
    const showObsolete = props.showObsolete;

    const formatPhone = (num) => {
        if (num !== null && num !== "") {
            const areaCode = num.slice(0, 3);
            const firstThree = num.slice(3,6);
            const lastFour = num.slice(6,10);

            return `(${areaCode}) ${firstThree}-${lastFour}`
        }

        return ""
    }

    customer.herds.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
            return -1;
        }

        if (fa > fb) {
            return 1;
        }

        return 0;
    });

    return (
        <div className="CustomerDetail">
            <div className="Detail">
                <div style={{paddingBottom: 16}}>
                    <div className="Obsolete">{customer.obsolete? "Obsolete": ""}</div>
                    <div className="CustomerTitle">{customer.name}</div>
                    <div className="Subtext">{customer.email}</div>
                    <div className="Subtext">{formatPhone(contact.mobile_num)}</div>
                    <div className="Subtext">{'$ ' + customer.billing_rate.toFixed(2)}</div>
                    <div style={{height: 8}} />
                    <Button text="Add Herd" type="text" onClick={newHerd}/>
                    <Button text="Add Recurring Charge" type="text" onClick={newRIL} />
                    <Button text="Change Password" type="text" onClick={changePassword} />
                </div>
                <Button text="Edit Customer" onClick={editCustomer}/>
            </div>
            <div className="RightSide">
                <div className="Herds">
                    {
                        customer.herds.length === 0? <div className="NoHerds" style={{alignSelf: "center", paddingTop: 16}}>No Herds</div>:
                        customer.herds
                        .map((val, index) => { return [val, index]; })
                        .filter(([val, index]) => {
                            return showObsolete || !val.obsolete;
                        })
                        .map(([val, index]) => {
                            return <HerdCard API={api} key={index} id={index} herd={val} editHerd={editHerd}/>
                        })
                    }
                </div>
                <div className="RILS">
                    <p>Recurring Line Items</p>
                    {
                        customer.recurring_invoice_line_items.map((val) => {
                            return <div className="ril" key={val.id} onClick={() => {editRIL(val.id)}}>
                                <p>{val.category.name}</p>
                                <p>{'$ ' + val.rate.toFixed(2)}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

const HerdCard = (props) => {
    const api: API = props.API;
    const herd = props.herd;
    let type = "";
    const editHerd = props.editHerd;

    const region = herd.region? herd.region.name: "None";
    const breed = herd.breed? herd.breed.name: "None";
    const processing_center = herd.processing_center? herd.processing_center.name: "None";
    const milkings = herd.milkings_per_day;
    const history = useHistory();
    const [herdStats, setHerdStats] = useState([]);

    if (herd.is_association_member) {
        type = "Association Member";
    } else if (herd.is_official) {
        type = "Official"
    }

    const getHerdStats = async () => {
        setHerdStats(await api.getHerdResults(herd.id));
    };

    useEffect(() => {
        setHerdStats([]);
        getHerdStats();
    }, [herd]);


    const uploadData = () => {
        history.push(`/admin/dataentry/${props.herd.id}`);
    }

    const viewReports = () => {
        history.push(`/admin/reports/${props.herd.id}`)
    }

    return (
        <div className="HerdCard">
            <div className="Title">
                {herd.obsolete?
                <div className="Obsolete">Obsolete</div>
                    : null}
                <div className="Name">{herd.name}</div>
                <div className="Type">{type}</div>
                <Button type="text" text="Edit Herd" onClick={() => {editHerd(props.id)}}/>
                <br/>
                <Button type="text" text="View Reports" onClick={() => {viewReports(props.id)}}/>
                <br/>
                <br/>
                <Button text="Upload Data" size="small" onClick={uploadData}/>
            </div>
            <div className="HerdCardDetail">
                <div>
                    <p>Region</p><p>{region}</p>
                </div>
                <div>
                    <p>Breed</p><p>{breed}</p>
                </div>
                <div>
                    <p>Processing Center</p><p>{processing_center}</p>
                </div>
                <div>
                    <p>Milkings Per Day</p><p>{milkings}</p>
                </div>
            </div>
            <div className="HerdStats">
                <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>MW</th>
                        <th>BF</th>
                        <th>PN</th>
                        <th>SCC</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        herdStats.map((v) => {
                            const date = new Date(v.time_uploaded);
                            return <tr>
                                <td>{date.getMonth()+1}/{date.getDate()}/{date.getFullYear()}</td>
                                <td>{v.milk_weight.toFixed(2)}</td>
                                <td>{v.butterfat.toFixed(2)}</td>
                                <td>{v.protein.toFixed(2)}</td>
                                <td>{v.scc.toFixed(0)}</td>
                            </tr>;
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className="Comments">{herd.comments}</div>
        </div>
    );
}

export default CustomerDetail;
